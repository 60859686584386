<template>
  <!-- TODO: Add active class if this menu item is active. -->
  <div :class="{'active': active}" class="menu-item">
    <!-- Menu text -->
    <div class="menu-item-label-text">{{ text }}</div>
    <!-- Active indicator -->
    <div class="active-bar"></div>
  </div>
</template>

<script>
export default {
  name: 'MenuLink',
  props: {
    active: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: true
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.menu-item {
  @include hover-active-pointer();
  align-items: center;
  display: flex;
  flex-direction: column;

  .menu-item-label-text {
    color: var(--purple_light);
    font-size: rem(16);
    letter-spacing: 0;
    line-height: rem(19);
    padding-bottom: rem(2);
  }

  &:not(:last-child) {
    margin-right: rem(30);
  }
}

/** Active styles **/
.active-bar {
  background-color: var(--pink_main);
  border-radius: 50vh;
  display: none;
  width: 100%;
  height: rem(3);
  margin-top: rem(3);
}

.active {
  .menu-item-label-text {
    color: var(--pink_main);
    font-weight: 500;
  }

  .icon {
    margin-left: rem(20);
  }

  .active-bar {
    display: block;
  }
}
</style>
