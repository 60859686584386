<template>
  <div>
    <div v-if="videoData.totalItems > 0" class="header">
      <h2 class="title">{{ $t('VIDEOS.OVERVIEW') }}</h2>
      <p class="video-count"> {{ videoData.totalItems }} {{ $t('VIDEOS.TOTAL_VIDEOS_OVERVIEW') }}</p>
    </div>
    <CustomTable
      :options="videoTableOptions"
      :table-data="videoData.items"
      :search-function="manageSearch"
      :hide-search="true"
      :less-padding="true"
      table-class="class-Instructions"
      :empty-state-data="emptyStateData"
      class="table"
    >
      <template #right-size-info>
        <p> {{ totalItems + $t('VIDEOS.TOTAL') }} </p>
      </template>
      <template #order="props" >
        <div>{{ props.rowData.id }}</div>
      </template>
      <template #name="props">
        <a class="downloadable" @click="downloadItem(props.rowData.fileName,props.rowData.name )">{{ props.rowData.name }}</a>
      </template>
      <template #size="props">
        <div>{{ props.rowData.fileSize }}</div>
      </template>
      <template #createdAt="props">
        <div>{{ new Date(props.rowData.createdAt).toLocaleDateString('nl') + ' ' + new Date(props.rowData.createdAt).toLocaleTimeString('nl') }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton :text="$t('DELETE')"
                        :icon="require('../../../assets/icons/icn_delete.svg')"
                        custom-class="button-red"
                        class="action"
                        @click="preDeleteVideo(props.rowData)"/>
        </div>
      </template>
    </CustomTable>
    <BasicModal v-if="isBasicVersionDisplayed"/>

  </div>
</template>

<script>
import BasicModal from '@/components/elements/basicModal/BasicModal'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'

import { computed, ref } from '@vue/reactivity'
import { GET_VIDEOS, DELETE_VIDEOS } from '@/store/modules/cms/actions'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { createEmptyTableData } from '@/utils/helpers/customTable/EmptyTableHelper'

import CustomTable from '@/components/table/CustomTable'
import ActionButton from '@/components/table/ActionButton'
import { getTableOptions, VIDEO_AND_ATTACHMENTS } from '@/utils/helpers/customTable/CustomTableHelper'
import nl from '@/utils/language/nl.json'
import Axios from 'axios'

export default {
  name: 'AttatchmentsView',
  components: {
    CustomTable,
    ActionButton,
    BasicModal,
  },
  props: {
    createVideo: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const store = useStore()

    const activeModal = computed(() => store.getters.getActiveBasicModal)

    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.DELETE_VIDEO
    )

    const videoData = computed(() => store.getters.getVideos)

    const emptyStateData = createEmptyTableData('VIDEOS', props.createVideo, require('@/assets/img/img_videoattachment@2x.png'))


    const modalController = ref(new ModalController())


    function downloadItem(url, label) {
      Axios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    }

    function preDeleteVideo(videoData) {
      modalController.value.setModal(BASIC_MODAL_CONTENT.DELETE_FILE(videoData.name), deleteVideo, null, videoData, MODAL_NAMES.DELETE_VIDEO)
    }

    function deleteVideo(videoData) {
      store.dispatch(DELETE_VIDEOS, videoData.id).then(response => {
        manageSearch()
        modalController.value.resetActiveModal()
      })
    }

    manageSearch()

    function manageSearch(payload) {
      if (!payload) {
        payload = {}
      }
      getInstructions(payload)
    }

    function getInstructions(payload) {
      return store.dispatch(GET_VIDEOS, payload)
    }

    return {
      videoData,
      emptyStateData,
      videoTableOptions: getTableOptions(VIDEO_AND_ATTACHMENTS),

      isBasicVersionDisplayed,
      preDeleteVideo,
      manageSearch,
      downloadItem
    }
  },
  template: 'Instructions'
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.table {
  margin-top: rem(8);
}

.downloadable{
  color: var(--blue_link);
  font-weight: 500;
  cursor: pointer;
}

.header{
  display: flex;
}

.video-count{
  margin: auto 0 0 auto;
  color: gray;
  text-align: right;
}
</style>
